import React from 'react'
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

import { About, Footer, Header, Skills, Testimonial, Work } from './container'
import { Navbar } from './components'
import './App.scss'

ReactGA.initialize('G-GMRNFR95V0');

export const NavigationTabs = ["home", "career", "projects", "skills", "publications", "contact"]


const App = () => {

    ReactGA.send({ hitType: "pageview", page: "/App", title: "Main App" });

    <Helmet>
        <link rel="canonical" href="https://ismailtaleb.com/" />
    </Helmet>


    return (
        <div className='app'>
            <Navbar />
            <Header />
            <About />
            <Work />
            <Skills />
            <Testimonial />
            <Footer />
        </div>
    )
}

export default App