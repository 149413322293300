import React from "react";
import { motion } from "framer-motion";
import { AppWrap } from "../../wrapper";
import { images } from "../../constants";

import "./Header.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

function Header() {
  return (
    <div id="home" className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span className="emoji">😁</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text sub-text">👋Hello, I am</p>
              <h1 className="head-text">Ismail</h1>
            </div>
          </div>
          <div className="tag-cmp">
            <h2 className="head-text" style={{ textAlign: "right" }}>
              Credentials&nbsp;🎓
            </h2>
            <p className="p-text">PhD👨🏻‍🎓</p>
            <p className="p-text">Research Masters🔎</p>
            <p className="p-text">Engineering Diploma📜</p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img
          style={{
            borderRadius: "50%",
            objectFit: "cover",
            height: "auto", // Or any specific value
            width: "100%", // Or any specific value
          }}
          src={images.profile}
          alt="profile_bg"
        ></img>
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="overlay_circle"
          src={images.circle}
          alt="profile_circle"
        />
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.react, images.javascript, images.node /* , images.git */].map(
          (circle, index) => (
            <div className="circle-cmp app__flex" key={`circle-${index}`}>
              <img src={circle} alt="circle" />
            </div>
          )
        )}
      </motion.div>
    </div>
  );
}

export default AppWrap(Header, "home");
