import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";

function Footer() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = formData;

  const validateEmail = (email) => {
    const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(email);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    // clear errors on input change
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = () => {
    if (validateEmail(email) && message.trim() !== "" && name.trim() !== "") {
      setLoading(true);

      const contact = {
        _type: "contact",
        name,
        email,
        message,
      };

      client.create(contact).then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      });
    } else {
      setErrors({
        name: name.trim() === "" ? "Name is required" : "",
        email: !validateEmail(email) ? "Email is not valid" : "",
        message: message.trim() === "" ? "Message is required" : "",
      });
    }
  };

  return (
    <>
      <h2 className="head-text">Let's get in touch!</h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email-logo" />
          <p className="p-text">Send me an email! 😁</p>
        </div>
        {/* <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:ismail@ismailtaleb.com" className="p-text">
            ismail@ismailtaleb.com
          </a>
        </div> */}
        {/* <div className="app__footer-card">
        <img src={images.mobile} alt="mobile" />
        <a href="tel:phone-number" className="p-text">
          phonenumber
        </a>
      </div> */}
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={handleChangeInput}
              name="name"
            />
            {errors.name && <p className="error-text">{errors.name}</p>}
          </div>
          <div className="app__flex input-container">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={handleChangeInput}
              name="email"
            />
            {errors.email && <p className="error-text">{errors.email}</p>}
          </div>
          <div style={{ position: "relative" }}>
            {errors.message && (
              <p
                className="error-text"
                style={{
                  position: "absolute",
                  top: 15,
                  right: "0px",
                  color: "red",
                  zIndex: 1,
                }}
              >
                {errors.message}
              </p>
            )}
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
              style={{ paddingTop: "20px" }}
            ></textarea>
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>
            {loading ? "Sending..." : "Send"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="p-text">Thank you for getting in touch!</h3>
        </div>
      )}
    </>
  );
}

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
