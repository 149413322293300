import React from "react";
import { SiSubstack, SiGithub } from "react-icons/si";
import { FaLinkedinIn /* , FaTwitter */ } from "react-icons/fa";

function SocialMedia() {
  return (
    <div className="app__social">
      <a
        href="https://www.linkedin.com/in/talebismail/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <FaLinkedinIn />
        </div>
      </a>
      <a
        href="https://github.com/ElliIsmail"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <SiGithub />
        </div>
      </a>
      {/* <a
        href="https://twitter.com/isma_tlb"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <FaTwitter />
        </div>
      </a> */}
      <a
        href="https://ismailtaleb.substack.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <SiSubstack />
        </div>
      </a>
    </div>
  );
}

export default SocialMedia;
