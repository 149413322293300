import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Testimonial.scss";

//Renamed as Publications

function Testimonial() {
  const [brands, setBrands] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullTextShown, setIsFullTextShown] = useState(false);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  const handleShowMoreOrLess = () => {
    setIsFullTextShown(!isFullTextShown);
  };

  useEffect(() => {
    const testimonialsQuery = '*[_type == "testimonials"]';
    const brandsQuery = '*[_type == "brands"]';
    client.fetch(testimonialsQuery).then((testimonials) => {
      setTestimonials(testimonials);
    });
    client.fetch(brandsQuery).then((brands) => {
      setBrands(brands.sort((a, b) => a.order - b.order));
    });
  }, []);

  const testimonial = testimonials[currentIndex];

  return (
    <>
      {testimonials.length && (
        <>
          <h2 className="head-text">
            📝Publications <span>& 📑Articles</span>
          </h2>
          <div className="app__testimonial-item app__flex">
            <img src={urlFor(testimonial.imgurl)} alt="testimonial" />
            <div className="app__testimonial-content">
              <p className="p-text">
                {" "}
                {isFullTextShown
                  ? testimonial.feedback
                  : `${testimonial.feedback.substring(0, 100)}...`}
              </p>
              {
                <button
                  className="app__testimonial-btns app__flex show-button"
                  onMouseOver={(event) => (event.target.style.opacity = "0.7")}
                  onMouseOut={(event) => (event.target.style.opacity = "1")}
                  onClick={handleShowMoreOrLess}
                >
                  {isFullTextShown ? "Show Less" : "Show More"}
                </button>
              }

              <div>
                <h4 className="bold-text">{testimonial.name}</h4>
                <h5 className="p-text">{testimonial.company}</h5>
              </div>
            </div>
          </div>
          <div className="app__testimonial-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand._id}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
}

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "publications",
  "app__primarybg"
);
