import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import { urlFor, client } from "../../client";
import { Tooltip } from "react-tooltip";

function About() {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client
      .fetch(query)
      .then((data) => setAbouts(data.sort((a, b) => a.order - b.order)));
  }, []);

  return (
    <>
      <h2 className="head-text">
        <span>📝Summary</span> of My Career
        <br />
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacitiy: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item title-container"
            key={about.title + index}
          >
            <img
              src={urlFor(about.imgUrl)}
              alt={about.title}
              data-tooltip-id={about.title}
              data-tooltip-content={about.title}
            />
            <h2
              className="bold-text title-text-special"
              style={{ marginTop: 20 }}
            >
              {about.title}
            </h2>
            <Tooltip
              id={about.title}
              className="title-diff-arrow"
              classNameArrow="title-arrow"
            />

            <p className="p-text" style={{ marginTop: 10 }}>
              {about.place}
            </p>
            <p className="p-text" style={{ marginTop: 10 }}>
              {about.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
}

export default AppWrap(
  MotionWrap(About, "app__about"),
  "career",
  "app__whitebg"
);
